import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/Layout';
import DeviceHero from '../../components/DeviceHero';
import { deviceDataFromDeviceAsset } from '../../utils/contentfulHelpers';
import SideBySideSection from '../../components/SideBySideSection';
import DeviceSection from '../../components/DeviceSection';
import DemoBanner from '../../components/DemoBanner';
import Wayfinding from '../../components/Wayfinding';
import ResourcesSection from '../../components/ResourcesSection';
import SiteMeta from '../../components/SiteMeta';

const SharePage = ({ data, location }) => {
  const {
    contentfulSharePage: {
      metaTitle,
      metaDescription: { metaDescription },
      heroModule,
      shareIntroductionModule,
      shareInfoModule1,
      // shareInfoModule2,
      // shareInfoModule3,
      demoCallout,
      wayfindingCardTitle,
      wayfindingCardSubheader: { wayfindingCardSubheader },
      wayfindingCards,
      featuredResources,
      featuredResourcesTitle,
      featuredResourcesSubheader: { featuredResourcesSubheader },
      featuredResourcesCtaText,
      featuredResourcesCtaLink,
    },
  } = data;
  const heroDeviceData = deviceDataFromDeviceAsset(heroModule.deviceAsset);

  const wayfindingData = {
    title: wayfindingCardTitle,
    subtitle: wayfindingCardSubheader,
    items: wayfindingCards.map(
      ({ wayfindingCardTitle, wayfindingCardBody, matchUrl }) => {
        return {
          title: wayfindingCardTitle,
          body: wayfindingCardBody.wayfindingCardBody,
          matchUrl,
        };
      },
    ),
    currentPath: location.pathname,
  };

  const resourcesData = {
    title: featuredResourcesTitle,
    subtitle: featuredResourcesSubheader,
    ctaText: featuredResourcesCtaText,
    ctaUrl: featuredResourcesCtaLink,
    featuredResources: featuredResources
  };

  const deviceSections = [
    shareInfoModule1
    // shareInfoModule2,
    // shareInfoModule3
  ]

  return (
    <Layout>
      <SiteMeta title={metaTitle} description={metaDescription} />
      <DeviceHero
        title={heroModule.title}
        body={heroModule.body}
        {...heroDeviceData}
      />
      <SideBySideSection layoutVariant={'mediaRight'} barTheme={'transparent'} {...shareIntroductionModule} />
      {deviceSections.map((section, index) => {
        const layout = index % 2 ? 'deviceRight' : 'deviceLeft';
        const deviceData = deviceDataFromDeviceAsset(section.deviceAsset)
        return (
          <DeviceSection {...section} {...deviceData} key={index} layoutVariant={layout} />
        );
      })}
      <Wayfinding {...wayfindingData} />
      <DemoBanner {...demoCallout} theme="dark" />
      <ResourcesSection {...resourcesData} />
    </Layout>
  );
};

export default SharePage;

export const query = graphql`
  query {
    contentfulSharePage {
      metaTitle
      metaDescription {
        metaDescription
      }
      heroModule {
        ...DeviceHero
      }
      shareIntroductionModule{
        ...Navigator
      }
      shareInfoModule1 {
        ...DeviceNavigator
      }
      demoCallout {
        ...Navigator
      }
      wayfindingCardTitle
      wayfindingCardSubheader {
        wayfindingCardSubheader
      }
      wayfindingCards {
        ...WayfindingCard
      }
      featuredResources {
       ...ResourceCard
      }
      featuredResourcesTitle
      featuredResourcesSubheader {
       featuredResourcesSubheader
      }
      featuredResourcesCtaText
      featuredResourcesCtaLink
    }
  }
`;
